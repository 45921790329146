<template>
  <div class="border-top-info border-3 border-table-radius px-0">
    <filter-slot
      :filter-principal="filterPrincipal"
      :filter="filters"
      :total-rows="totalRows"
      :paginate="paginate"
      :start-page="startPage"
      :to-page="toPage"
      @reload="getPendingPayments"
      @onChangeCurrentPage="onChangeCurrentPage"
    >
      <template #buttons>
        <div class="ml-3" v-if="payments.length > 0">
          <b-badge class="p-1 text-success" variant="light-success">
            <span> RECOVERED PAYMENTS : </span>
            <span class="ml-3">
              {{ payments[0].recovered_sum }}
            </span>
          </b-badge>
        </div>
      </template>
      <template #custom-vselect>
        <FilterStatusAccount :filters="filters[2]" />
      </template>
      <template #table>
        <b-table
          id="refPendingPaymentGridId"
          slot="table"
          :ref="`refOfTheMonth${typeTab}`"
          small
          :items="payments"
          :fields="tabFields"
          primary-key="id"
          table-class="text-nowrap"
          responsive="sm"
          show-empty
          sticky-header="70vh"
          :busy.sync="isBusy"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
        >
          <template #table-busy>
            <div class="text-center text-primary my-2">
              <b-spinner class="align-middle mr-1" />
              <strong>Loading ...</strong>
            </div>
          </template>
          <template #cell(client_name)="data">
            <div>
              <router-link
                :class="[textLink]"
                :to="{
                  name: dashboardRouteName,
                  params: {
                    idClient: data.item.client_account_id,
                  },
                }"
                target="_blank"
                >{{ data.item.client_name }}
              </router-link>
              <span v-if="data.item.user_responsible" class="text-warning">
                <tabler-icon
                  :icon="
                    data.item.user_responsible === currentUser.user_id
                      ? 'StarIcon'
                      : 'LockIcon'
                  "
                  size="15"
                />
                <strong>CEO's client</strong>
              </span>
              <status-account :account="data.item" />
              <p class="m-0">
                {{ data.item.account }}
              </p>
              <p class="m-0">
                {{ data.item.mobile ? data.item.mobile : "(###) ###-####" }}
              </p>

              <b-badge variant="primary">
                {{ data.item.client_type }}
              </b-badge>
              <b-badge
                v-if="data.item.pay_state"
                variant="danger"
                style="margin-left: 2px"
              >
                OVER FLOW
              </b-badge>
            </div>
          </template>
          <template #cell(start_date)="data">
            {{ data.item.start_date | myGlobal }}
          </template>
          <template #cell(payment_type)="data">
            <div class="d-flex pt-1">
              <img
                v-if="
                  data.item.type_payment == 'PA' &&
                  data.item.status_payment == '1' &&
                  data.item.srb == 'ACTIVE'
                "
                :title="'Automatic/' + data.item.srb"
                :src="'/assets/images/social-network/paymentType/paymenttype-auto.ico'"
                alt=""
              />
              <img
                v-if="
                  data.item.type_payment == 'PA' &&
                  data.item.status_payment == '0' &&
                  data.item.srb != 'ACTIVE'
                "
                :title="'Automatic/' + data.item.srb"
                :src="'/assets/images/social-network/paymentType/paymenttype-auto-no.ico'"
                alt=""
              />
              <img
                v-if="
                  data.item.type_payment == 'PA' &&
                  data.item.status_payment == '1' &&
                  data.item.srb != 'ACTIVE'
                "
                :title="'Automatic/' + data.item.srb"
                :src="'/assets/images/social-network/paymentType/paymenttype-auto-no.ico'"
                alt=""
              />
              <img
                v-if="data.item.type_payment == 'MA'"
                title="Manual"
                :src="'/assets/images/social-network/paymentType/paymenttype-manual.ico'"
                alt=""
              />
              <img
                v-if="data.item.type_payment == 'OT'"
                title="Others"
                :src="'/assets/images/social-network/paymentType/paymenttype-others.ico'"
                alt=""
              />
              <span v-if="data.item.day_payment != null">
                ({{ data.item.day_payment }})
              </span>
              <feather-icon
                icon="Edit2Icon"
                class="cursor-pointer ml-1"
                size="15"
                @click="openModalMonthlyPayment(data.item)"
              />
              <feather-icon
                icon="MoreVerticalIcon"
                class="cursor-pointer ml-1"
                size="15"
                @click="openModalHistoryPayment(data.item)"
              />
            </div>

            <!-- openModalHistoryPayment -->
          </template>
          <template #cell(balance)="data">
            <div class="text-center d-flex justify-content-center pt-1">
              <b-button
                variant="outline"
                class="m-0 p-0 pt-0"
                @click="openBalance(data.item)"
              >
                <span
                  style="color: #f74f4f; text-align: center"
                  class="cursor-pointer"
                  :class="[
                    data.item.balance < 0 ? 'text-danger' : 'text-success',
                  ]"
                  >$ {{ data.item.balance | currencyZero }}</span
                >
              </b-button>
            </div>
          </template>

          <template #cell(amount)="data">
            <div class="d-flex justify-content-center pt-1">
              <money
                v-model="data.item.monthly_amount"
                class="m-0 p-0"
                v-bind="maskMoney"
                style="
                  border: 0px solid transparent;
                  color: #8c8996;
                  text-align: center;
                  cursor: pointer;
                  width: 80px;
                "
                disabled
              />
              <span>
                <feather-icon
                  v-if="G_PENDING_PAYMENTS_MONTH == new Date().getMonth() + 1"
                  v-b-tooltip.hover.right="'Process Payment'"
                  icon="CheckCircleIcon"
                  size="20"
                  class="text-success cursor-pointer"
                  @click="processPayment(data.item, data.index)"
                />
                <feather-icon
                  v-else
                  v-b-tooltip.hover.right="'Process Payment'"
                  icon="CheckCircleIcon"
                  size="20"
                  disabled
                />
                <span class="text-muted" v-if="!!data.item.type_payment_ce">
                  ({{ data.item.type_payment_ce === 1 ? "SP" : "MP" }})
                </span>
              </span>
            </div>
          </template>

          <template #cell(client_state)="data">
            <div class="pt-1">{{ data.item.client_state }}</div>
          </template>
          <template #cell(recovered)="data">
            <div class="pt-1">
              <feather-icon
                v-if="data.item.recovered == 1"
                icon="CheckIcon"
                class="text-success"
                size="20"
              />
            </div>
          </template>
          <template #cell(actions)="data">
            <div class="d-flex align-items-center">
              <div class="mr-1">
                <feather-icon
                  size="20"
                  icon="MessageSquareIcon"
                  class="text-primary cursor-pointer"
                  @click="openAddSmsModal(data.item, 2)"
                />
              </div>
              <div>
                <feather-icon
                  size="20"
                  icon="ListIcon"
                  class="text-primary cursor-pointer"
                  @click="openSmsHistoryModal(data.item.client_account_id)"
                />
              </div>
            </div>
          </template>
        </b-table>
      </template>
    </filter-slot>

    <add-sms-modal
      v-if="addSmsModal"
      :client-data="clientData"
      :action-type="actionType"
      @refresh="getPendingPayments"
      @closeModal="addSmsModal = false"
      @onAddSms="onAddSms"
    />
    <modal-process-payment
      v-if="stateProcessPayment"
      :payment-information="paymentInformation"
      :type-tab="paymentInformation.type_month"
      @close="stateProcessPayment = false"
      @hideModal="stateProcessPayment = false"
      @onManualPay="refreshTableOnProcessPayment"
      @onOtherPay="refreshTableOnProcessPayment"
      @refreshTable="refreshTableManually"
    />
    <modal-balance
      v-if="stateBalance"
      :payment-information="paymentInformation"
      @hideModal="stateBalance = false"
    />
    <add-donot-process
      v-if="modalDoNotProcess"
      :client="clientInformationT"
      @hideModal="closeDoNotProcess"
      @refreshField="refreshField"
    />
    <modal-monthly-edit
      v-if="modalMonthlyPayment"
      :client-account="clientInformationT"
      :modal-monthly-payment="modalMonthlyPayment"
      @refresh="refreshMonthlyEdit"
      @close="modalMonthlyPayment = false"
    />
    <modal-history-monthly-payment
      v-if="modalHistoryPayment"
      :client-prop="selectedClientTwo"
      @close="closeModalHistoryPayment"
    />
    <payment-schedule
      v-if="showPaymentSchedule"
      :payment-information="paymentInformation"
      @hidden="showPaymentSchedule = false"
      @onManualPay="refreshTableOnProcessPayment"
      @onOtherPay="refreshTableOnProcessPayment"
      @refreshTable="getPendingPayments"
    />

    <b-modal
      v-if="showModalDashboardPayments"
      v-model="showModalDashboardPayments"
      @hidden="showModalDashboardPayments = false"
      size="xl"
      hide-footer
      :title="'Dashboard Payment: ' + paymentInformation.client_name"
    >
      <dashboard-payments
        v-if="showModalDashboardPayments"
        :client-account-id="paymentInformation.client_account_id"
        :read-only="true"
      ></dashboard-payments>
    </b-modal>

    <sms-client-history
      v-if="showSmsHistoryModal"
      :client-account-id="currentClientAccountId"
      @close="closeSmsHistoryModal"
    />
  </div>
</template>
  
  <script>
import { mapGetters } from "vuex";
import StatusPayment from "@/views/commons/components/clients/stylescomponents/StatusPayment.vue";
import Feather from "@/views/ui/feather/Feather";
import NotificationAppModal from "@/views/ce-digital/sub-modules/financial/view/pending-payments/view/components/modal/NotificationAppModal.vue";
import AddSmsModal from "@/views/ce-digital/sub-modules/financial/view/calls-and-sms/components/modals/AddSmsModal.vue";
import AddZeroPayment from "@/views/ce-digital/sub-modules/financial/view/pending-payments/view/components/modal/AddZeroPayment.vue";
import BadgeRiskyClients from "@/views/commons/components/risky-clients/BadgeRiskyClients";
import ViewClientDetails from "@/views/ce-digital/sub-modules/financial/components/ViewClientDetails.vue";
import ResultSelect from "@/views/commons/components/reports/tabs/debtor-clients/components/ResultSelect.vue";
import ClientLabelSelect from "@/views/commons/components/reports/tabs/debtor-clients/components/ClientLabelSelect.vue";
import ModalResultTracking from "@/views/commons/components/reports/tabs/debtor-clients/components/ModalResultTracking.vue";
import ModalMonthlyEdit from "@/views/commons/components/clients/dashboard/information-client/modals-client-information/ModalMonthlyEdit.vue";
import ModalHistoryMonthlyPayment from "@/views/commons/components/clients/dashboard/information-client/modals-client-information/ModalHistoryMonthlyPayment.vue";
import fields from "@/views/ce-digital/sub-modules/financial/view/pending-payments/view/data/fields.autoDeclined.js";
import Filters from "@/views/ce-digital/sub-modules/financial/view/pending-payments/view/data/filters.OfTheMonthOverDue.data.js";
import TypesOfPayments from "../../../ce-digital/sub-modules/financial/view/pending-payments/view/components/TypesOfPayments.vue";
import SmsClientHistory from "@/views/ce-digital/sub-modules/financial/view/clients/components/modales/SmsClientHistory.vue";

import PendingPaymentsService from "@/views/ce-digital/sub-modules/financial/view/pending-payments/service/pending-payments.service.js";
import NcrLeadsService from "@/views/administration/views/ncr-leads/services/NcrLeads.service.js";

import ModalProcessPayment from "../../../ce-digital/sub-modules/financial/view/pending-payments/view/components/modal/ModalProcessPayment.vue";
import ModalBalance from "../../../ce-digital/sub-modules/financial/view/pending-payments/view/components/modal/ModalBalance.vue";
import AddDonotProcess from "../../../ce-digital/sub-modules/financial/view/pending-payments/view/components/modal/AddNotProcess.vue";
import ShowMoreText from "@/views/ce-digital/sub-modules/financial/view/clients/components/ShowMoreText.vue";
import PaymentSchedule from "@/views/ce-digital/sub-modules/financial/view/clients/components/modales/PaymentScheduleModal.vue";
import DashboardPayments from "@/views/commons/components/clients/dashboard/options/pay-module/components/DashboardPayments.vue";
import StatusAccount from "@/views/commons/components/clients/stylescomponents/StatusAccount.vue";
import FilterStatusAccount from "@/views/commons/components/clients/stylescomponents/FilterStatusAccount.vue";

export default {
  components: {
    ModalMonthlyEdit,
    AddSmsModal,
    Feather,
    StatusPayment,
    ModalProcessPayment,
    TypesOfPayments,
    AddZeroPayment,
    ModalBalance,
    NotificationAppModal,
    BadgeRiskyClients,
    ViewClientDetails,
    ResultSelect,
    ClientLabelSelect,
    ModalResultTracking,
    AddDonotProcess,
    ShowMoreText,
    ModalHistoryMonthlyPayment,
    PaymentSchedule,
    DashboardPayments,
    FilterStatusAccount,
    StatusAccount,
    SmsClientHistory,
  },
  data() {
    return {
      modalMonthlyPayment: false,
      showModalDashboardPayments: false,
      currentIndexRow: null,
      filterPrincipal: {
        type: "input",
        inputType: "text",
        placeholder: "Search...",
        model: "",
      },
      paginate: {
        currentPage: 1,
        perPage: 50,
      },
      totalRows: 0,
      startPage: null,
      endPage: null,
      toPage: null,
      fields,
      addSmsModal: false,
      stateProcessPayment: false,
      paymentInformation: {},
      clientData: {},
      actionType: null,
      typeTracking: null,
      stateBalance: false,
      stateZeroPayment: false,
      client: {},
      isBusy: false,
      maskMoney: {
        decimal: ".",
        thousands: ",",
        prefix: "$",
        precision: 2,
        masked: false,
        maxlength: 11,
      },
      sortBy: "",
      sortDesc: true,
      filters: Filters,
      modalClientDetailC: false,
      clientInformationT: {},
      clientSelected: null,
      payments: [],
      name_clients_arr: [],
      results: [],
      labels: [],
      transaction_id: null,
      other: null,
      modalDoNotProcess: false,
      selectedItem: null,
      modalHistoryPayment: false,
      selectedClientTwo: {
        id: null,
        program_name: null,
        client_name: null,
      },
      account_id: null,
      showPaymentSchedule: false,
      currentClientAccountId: null,
      showSmsHistoryModal: false,
    };
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
      G_EEUU_STATES: "CrmGlobalStore/G_EEUU_STATES",
      RELOAD_TRANSACTIONS_STATUS: "TransactionStatusStore/G_REFRESH",
      G_PROGRAMS_IN_NEW_PAYMENT: "TransactionStatusStore/G_PROGRAMS",

      G_PENDING_PAYMENTS_MONTH: "FinancialStore/G_PENDING_PAYMENTS_MONTH",
      G_PENDING_PAYMENTS_YEAR: "FinancialStore/G_PENDING_PAYMENTS_YEAR",
      G_PENDING_PAYMENTS_REFRESH: "FinancialStore/G_PENDING_PAYMENTS_REFRESH",
    }),

    currentTabDay() {
      return this.$route.params.day;
    },

    typeTab() {
      return 2;
    },
    tabFields() {
      if (
        this.typeTab == 1 ||
        this.typeTab == 4 ||
        this.typeTab == 5 ||
        this.typeTab == 6
      ) {
        return this.fields.filter((item) => item.key != "type_month");
      }
      return this.fields.filter(
        (item) => item.key != "day_payment" && item.key != "type_month"
      );
    },
    moduleId() {
      return this.$route.matched[0].meta.module;
    },
    dashboardRouteName() {
      const routers = {
        2: "dashboard-crm",
        3: "bussiness-dashboard-clients",
        4: "administration-dashboard",
        5: "debt-solution-dashboard",
        6: "credit-experts-dashboard",
        7: "boost-credit-dashboard",
        8: "tax-research-client-dashboard",
        10: "claim-department-dashboard",
        11: "specialists-client-dashboard",
        12: "paragon-dashboard",
        14: "bookeeping-dashboard",
        16: "management-dashboard",
        18: "quality-client-dashboard",
        20: "connection-clients-account",
        21: "ce-customer-service-dashboard",
        22: "ce-customer-service-dashboard",
        23: "financial-clients-account",
        25: "specialist-digital-dashboard",
        29: "specialists-client-dashboard-fa",
        16: "management-client-dashboard",
      };
      return routers[this.moduleId] || null;
    },
    programId() {
      return (
        this.$route.matched[0].meta.program ??
        this.$route.matched[1].meta.program_id
      );
    },
    typeTransaction() {
      return this.$route.meta.typeTransaction;
    },
  },
  async created() {
    this.addPreloader();
    await this.getAllStatusClientAccount();

    this.removePreloader();
  },
  methods: {
    openSmsHistoryModal(clientAccountId) {
      this.currentClientAccountId = clientAccountId;
      this.showSmsHistoryModal = true;
    },
    closeSmsHistoryModal() {
      this.currentClientAccountId = null;
      this.showSmsHistoryModal = false;
    },
    openPaymentSchedule(item) {
      this.paymentInformation = item;
      this.showPaymentSchedule = true;
    },
    refreshField(id) {
      // en this.payments quitar el que tiene id
      this.payments = this.payments.filter(
        (item) => item.client_account_id != id
      );
    },
    onSaveSelection(item, labelId, status) {
      this.$set(item, "client_label_id", labelId);
      this.$set(item, "status", status);
    },
    openModalMonthlyPayment(client) {
      this.clientInformationT = {
        program_name: client.program_name,
        client_name: client.client_name,
        program_min: client.program_min,
        lead_id: client.lead_id,
        id: client.client_account_id,
        program_id: client.program_id,
        charges: client.charges,
      };
      this.modalMonthlyPayment = true;
    },
    openModalHistoryPayment(client) {
      this.selectedClientTwo = {
        program_name: client.program_name,
        client_name: client.client_name,
        program_min: client.program_min,
        lead_id: client.lead_id,
        id: client.client_account_id,
        program_id: client.program_id,
        charges: client.charges,
      };
      this.modalHistoryPayment = true;
    },
    closeModalHistoryPayment() {
      this.modalHistoryPayment = false;
    },
    hasSameOptionSelected(value, item) {
      this.$set(item, "editingResult", value);
    },
    async getAllStatusClientAccount() {
      this.filters[2].options = [];
      const { data } = await NcrLeadsService.getAllStatusClientAccount();
      this.filters[2].options.push({
        value: null,
        label: "All",
        icon: "CircleIcon",
      });

      // Filter data to include only items with values 8, 9, and 10
      const filteredData = data.filter((item) =>
        [8, 9, 10].includes(item.value)
      );

      this.filters[2].options.push(...filteredData);
    },
    closeDoNotProcess() {
      this.modalDoNotProcess = false;
    },
    async modalDoNotProcessOpen(client) {
      if (client.type_payment !== "PA") {
        this.clientInformationT = client;
        this.modalDoNotProcess = true;
      } else {
        this.showInfoSwal(
          "You have to change the type of payment to manual or other before the client switches to Do Not Process"
        );
      }
    },
    async sendToTheEndOfList(client) {
      try {
        const { isConfirmed } = await this.showConfirmSwal(
          "Are you sure?",
          `You are about to send ${client.client_name} to the end of the list`
        );
        if (isConfirmed) {
          this.addPreloader();
          await PendingPaymentsService.sendToTheEnd({
            client_account_id: client.client_account_id,
          });
          // get payment with client of payments array
          const payment = this.payments.find(
            (item) => item.client_account_id == client.client_account_id
          );
          // remove payment of payments array
          this.payments = this.payments.filter(
            (item) => item.client_account_id != client.client_account_id
          );
          if (this.totalRows == this.payments.length) {
            this.payments.push(payment);
          }
        }
      } catch (error) {
        console.error(error);
      } finally {
        this.removePreloader();
      }
    },
    processPayment(item, index) {
      this.currentIndexRow = index + 1;
      this.paymentInformation = item;
      this.stateProcessPayment = true;
    },
    onAddSms(item) {
      this.$set(item, "count_sms", Number(item.count_sms) + 1);
      this.addSmsModal = false;
    },

    async refreshTableOnProcessPayment(payment, paymentMethod, preventCommonTableAutoRefresh = false) {
      if (preventCommonTableAutoRefresh) {
        // this.$set(this.payments[this.currentIndexRow], 'recovered', 1);
        this.payments.forEach( (item, index) => {
          if (index === this.currentIndexRow - 1) {
            // this.item.recovered = 1
            this.$set(item, 'recovered', 1)
          }
        });
      } else {
        await this.getPendingPayments();
      }
      // if (payment) {
      //   // delete payment by client_account_id
      //   if (paymentMethod == "manual") {
      //     this.payments = this.payments.filter(
      //       (item) => item.client_account_id != payment.client_account_id
      //     );
      //   } else {
      //     this.$set(payment, "other_pending", 1);
      //   }
        //this.paymentInformation = null;
        //this.stateProcessPayment = false;
      // }
    },
    onZeroPayment(client) {
      // find payment by client_account_id
      const payment = this.payments.find(
        (item) => item.client_account_id == client.client_account_id
      );
      this.$set(payment, "zero_payment_pending", 1);
      this.stateZeroPayment = false;
      this.client = null;
    },
    updatePendingPaymentGrid(client_account_id) {
      this.payments = this.payments.map((item) => {
        if (item.client_account_id == client_account_id) {
          item.reschedule = "active";
        }
        return item;
      });
    },
    refreshMonthlyEdit(monthlyChange) {
      const row = this.payments.find(
        (item) => item.client_account_id == monthlyChange.id
      );
      row.method_payment = monthlyChange.method_payment;
      row.day_payment = monthlyChange.day_payment;
      row.monthly_amount = Number(monthlyChange.monthly_amount);
      if (
        monthlyChange.type_payment == 1 &&
        monthlyChange.method_payment == 0
      ) {
        row.type_payment = "MA";
      } else if (
        monthlyChange.type_payment == 0 &&
        monthlyChange.method_payment == 0
      ) {
        row.type_payment = "PA";
      } else {
        row.type_payment = "OT";
      }
    },
    async getPendingPayments(nPage) {
      try {
        this.isBusy = true;

        let month = null;
        let year = null;

        if (this.G_PENDING_PAYMENTS_YEAR) {
          year = this.G_PENDING_PAYMENTS_YEAR;
        } else {
          year = new Date().getFullYear();
        }

        if (this.G_PENDING_PAYMENTS_MONTH) {
          month = this.G_PENDING_PAYMENTS_MONTH;
        } else {
          month = new Date().getMonth() + 1;
        }

        const fromDate = year + "-" + month + "-" + this.currentTabDay ?? "01";
        const fechaInicial = new Date(fromDate);
        if (this.typeTransaction == 1) {
          fechaInicial.setDate(fechaInicial.getDate() + 1);
        } else {
          fechaInicial.setDate(fechaInicial.getMonth() + 1);
          fechaInicial.setDate(fechaInicial.getDate() - 1);
        }
        const toDate = fechaInicial.toISOString().slice(0, 10);
        const params = {
          date_from: fromDate,
          date_to: toDate,
          name_text: this.filterPrincipal.model,
          per_page: this.paginate.perPage,
          page: nPage || this.paginate.currentPage,
          type_transaction: this.typeTransaction,
          client_type: this.filters[0].model,
          recovered: this.filters[1].model,
          status: this.filters[2].model,
          program_id: this.programId,
        };
        const { status, data } =
          await PendingPaymentsService.getPendingPaymentsV2(params);
        const payments = data.data;

        if (status == 200) {
          this.startPage = data.from;
          this.totalRows = data.total;
          this.endPage = data.last_page;
          this.toPage = data.to;
          this.payments = payments || [];
          return this.payments;
        }
      } catch (error) {
        console.log(error);
      } finally {
        this.isBusy = false;
      }
    },
    onChangeCurrentPage(e) {
      this.paginate.currentPage = e;
      this.getPendingPayments();
    },

    openAddSmsModal(item, type) {
      this.clientData = item;
      this.actionType = type;
      if (this.clientData.mobile !== null) {
        this.addSmsModal = true;
      } else {
        this.showToast(
          "warning",
          "top-right",
          "Warning!",
          "AlertTriangleIcon",
          "This client don't have a mobile registered."
        );
      }
    },
    zeroPayment(item) {
      this.stateZeroPayment = true;
      this.client = {
        client_name: item.client_name,
        program_name: "Credit Experts",
        client_type: item.client_type,
        client_account_id: item.client_account_id,
        client_account: item.account,
      };
    },
    openBalance(item) {
      if (this.G_PROGRAMS_IN_NEW_PAYMENT.includes(this.programId)) {
        this.showModalDashboardPayments = true;
      } else {
        this.stateBalance = true;
      }
      this.paymentInformation = item;
    },
    openModalClientDetail(client) {
      this.modalClientDetailC = true;
      this.clientInformationT = client;
    },
    setResult(e, data) {
      data.client_result_id = e;
    },
    onOtherLabel(e, a, item) {
      if (e == null) {
        item.client_label_status = 3;
        item.status = 3;
        item.other_label = null;
      } else {
        item.other_label = e;
        item.client_label_status = 1;
        item.status = 1;
      }
    },
    async refreshTableManually(preventCommonTableAutoRefresh = false) {
      if (preventCommonTableAutoRefresh) {
        // this.$set(this.payments[this.currentIndexRow], 'recovered', 1);
        this.payments.forEach((item, index) => {
          if (index === this.currentIndexRow - 1) {
            // this.item.recovered = 1
            this.$set(item, 'recovered', 1)
          }
        });
      } else {
        await this.getPendingPayments();
      }
    },
  },
  watch: {
    async RELOAD_TRANSACTIONS_STATUS() {
      await this.getPendingPayments();
    },

    async G_PENDING_PAYMENTS_MONTH() {
      await this.getPendingPayments();
    },

    async G_PENDING_PAYMENTS_YEAR() {
      await this.getPendingPayments();
    },

    async G_PENDING_PAYMENTS_REFRESH() {
      await this.getPendingPayments();
    },
  },
};
</script>
  
  <style scoped>
.pill {
  width: 60%;
  border-radius: 15px;

  color: rgb(255, 255, 255);
  padding: 6px 0px;
  margin: 8px 0px;
}

.cursor-pointer {
  cursor: pointer;
}
</style>
  