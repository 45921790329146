<template>
  <b-modal
    ref="notproc-modal"
    title="ADD MOTIVE TO NOT PROCESS"
    @hidden="hideModal"
    size="lg"
  >
    <b-row>
      <b-col>
        <b-form-group
          id="input-group-motive"
          label="Motive"
          label-for="input-motive"
          description="Please type a motive"
        >
          <b-textarea v-model="motive"> </b-textarea>
        </b-form-group>
      </b-col>
    </b-row>
    <template #modal-footer>
      <b-row>
        <b-col>
          <b-button @click="addNotProcess()" variant="success"> Save</b-button>
        </b-col>
      </b-row>
    </template>
  </b-modal>
</template>
<script>
import { mapGetters } from "vuex";
import PendingPaymentsService from "../../../service/pending-payments.service";
export default {
  data() {
    return {
      motive: "",
    };
  },
  props: {
    client: {
      type: Object,
    },
  },
  mounted() {
    this.toggleModal("notproc-modal");
  },
  methods: {
    hideModal() {
      this.$emit("hideModal");
    },
    async addNotProcess() {
      this.addPreloader();
      const params = {
        client_account_id: this.client.client_account_id,
        created_by: this.currentUser.user_id,
        motive: this.motive,
      };
      const data = await PendingPaymentsService.insertToNotProcess(params);
      if (data.status == 200) {
        // eliminar el elemento this.client.client_account_id,
        // de la tabla de pendientes de pago
        this.$emit("refreshField",this.client.client_account_id);

        this.showSuccessSwal();
        this.hideModal();
        this.removePreloader();

      }
    },
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
    }),
  },
};
</script>
<style></style>
