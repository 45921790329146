export default [
  {
    type: "select",
    margin: true,
    showLabel: true,
    label: "Client type",
    model: null,
    options: [
      { value: 1, label: "Regular" },
      { value: 2, label: "Digital" },
    ],
    reduce: "value",
    selectText: "label",
    cols: 12,
    visible: true,
  },
  {
    type: "select",
    margin: true,
    showLabel: true,
    label: "Recovered",
    model: null,
    options: [
      { value: 1, label: "RECOVERED" },
      { value: 0, label: "NOT RECOVERED" },
    ],
    reduce: "value",
    selectText: "label",
    cols: 12,
    visible: true,
  },
  {
    type: "custom-select",
    margin: true,
    showLabel: true,
    label: "Client Status",
    model: null,
    options: [],
    reduce: 'value',
    selectText: 'label',
    cols: 12,
    visible: true,
  },
  // {
  //   type: "select",
  //   margin: true,
  //   showLabel: true,
  //   label: "Day of payment",
  //   model: null,
  //   options: [
  //     { value: 5, label: "5" },
  //     { value: 10, label: "10" },
  //     { value: 15, label: "15" },
  //     { value: 20, label: "20" },
  //     { value: 25, label: "25" },
  //     { value: 30, label: "30" },
  //   ],
  //   reduce: "value",
  //   selectText: "label",
  //   cols: 12,
  //   visible: true,
  // },
  // {
  //   type: "select",
  //   margin: true,
  //   showLabel: true,
  //   label: "Scheduled",
  //   model: null,
  //   options: [
  //     { value: 1, label: "Active" },
  //     { value: 2, label: "Inactive" },
  //   ],
  //   reduce: "value",
  //   selectText: "label",
  //   cols: 12,
  //   visible: true,
  // },
  // {
  //   type: "select",
  //   margin: true,
  //   showLabel: true,
  //   label: "Payment Type",
  //   model: null,
  //   options: [
  //     {
  //       value: 1,
  //       label: "Automatic",
  //     },
  //     {
  //       value: 2,
  //       label: "Manual",
  //     },
  //     {
  //       value: 3,
  //       label: "Other",
  //     },
  //   ],
  //   reduce: "value",
  //   selectText: "label",
  //   cols: 12,
  //   visible: true,
  // },
  // {
  //   type: 'select',
  //   margin: true,
  //   showLabel: true,
  //   label: 'Result',
  //   model: null,
  //   options: [],
  //   reduce: 'value',
  //   selectText: 'text',
  //   cols: 12,
  //   visible: true,
  // },
  // {
  //   type: 'select',
  //   margin: true,
  //   showLabel: true,
  //   label: 'Label',
  //   model: null,
  //   options: [],
  //   reduce: 'value',
  //   selectText: 'text',
  //   cols: 12,
  //   visible: true,
  // },
  // {
  //   type: 'select',
  //   margin: true,
  //   showLabel: true,
  //   label: 'State',
  //   model: null,
  //   options: [],
  //   reduce: 'value',
  //   selectText: 'state',
  //   cols: 12,
  //   visible: true,
  // },

  // {
  //   type: 'k-timer',
  //   margin: true,
  //   showLabel: true,
  //   label: 'Contact Schedule From',
  //   model: null,
  //   options: [],
  //   reduce: 'id',
  //   selectText: 'text',
  //   cols: 6,
  //   visible: true,
  //   key: 'initial_fee_from',
  //   inputType: 'number',
  // },
  // {
  //   type: 'k-timer',
  //   margin: true,
  //   showLabel: true,
  //   label: 'Contact Schedule To',
  //   model: null,
  //   options: [],
  //   reduce: 'id',
  //   selectText: 'text',
  //   cols: 6,
  //   visible: true,
  //   key: 'initial_fee_to',
  //   inputType: 'number',
  // },

]