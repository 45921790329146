<template>
  <b-modal
    ref="addZeroPaymentModal"
    title="Add Zero Payment"
    @hidden="$emit('hideModal')"
    size="lg"
  >
    <b-row>
      <b-col>
        <b-card
          footer-class="p-2"
          header="CLIENT"
          header-class="p-1"
          header-tag="header"
          :header-bg-variant="isDarkSkin ? 'secondary' : 'primary'"
          header-text-variant="white"
          footer-tag="footer"
          no-body
        >
          <template #footer>
            <div style="margin: 9px">{{ client.client_name }}</div>
            <div style="margin: 9px">{{ client.client_type }}</div>
          </template>
        </b-card></b-col
      >
      <b-col
        ><b-card
          footer-class="p-2"
          header="PROGRAM"
          header-class="p-1"
          header-tag="header"
          :header-bg-variant="isDarkSkin ? 'secondary' : 'primary'"
          header-text-variant="white"
          footer-tag="footer"
          no-body
        >
          <template #footer>
            <div style="margin: 9px">{{ client.program_name }}</div>
          </template>
        </b-card></b-col
      >
      <b-col>
        <b-card
          footer-class="p-2"
          header="AMOUNT"
          header-class="p-1"
          :header-bg-variant="isDarkSkin ? 'secondary' : 'primary'"
          header-text-variant="white"
          header-tag="header"
          footer-tag="footer"
          no-body
        >
          <template #footer>
            <money
              id="input-2"
              v-model="ammount"
              v-bind="maskMoney"
              class="form-control"
              disabled
            />
          </template> </b-card
      ></b-col>
    </b-row>
    <validation-observer ref="form">
      <b-row>
        <b-col md="9">
          <validation-provider v-slot="{ errors }" name="DATE" rules="required">
            <b-input-group>
              <template #prepend>
                <b-input-group-text class="title_lable"
                  >DATE
                </b-input-group-text>
              </template>

              <kendo-datepicker
                id="date"
                v-model="date"
                :disabled="validateEdit"
                :min="minDate"
                :max="maxDate"
                :format="'MM/dd/yyyy'"
                class="input-form"
                name="date"
                :class="
                  errors[0]
                    ? 'w-auto rounded bg-transparent k-picker-custom border-error-datepicker picker-select-date'
                    : 'w-auto rounded bg-transparent k-picker-custom picker-select-date'
                "
              />
            </b-input-group> </validation-provider
        ></b-col>
      </b-row>
      <b-row class="mt-1">
        <b-col>
          <validation-provider
            v-slot="{ errors }"
            name="comment"
            rules="required"
          >
            <b-input-group>
              <template #prepend>
                <b-input-group-text class="title_lable"
                  >REASON
                </b-input-group-text>
              </template>

              <b-textarea
                v-model="content"
                :disabled="validateEdit"
                :state="errors[0] ? false : null"
              />
            </b-input-group> </validation-provider
        ></b-col>
      </b-row>
      <b-row v-if="zeroPaymentTransactionId" class="mt-1">
        <b-col>
          <b-input-group>
            <template #prepend>
              <b-input-group-text
                class="title_lable d-flex justify-content-center"
                >DONE BY
              </b-input-group-text>
            </template>
            <b-skeleton
              v-if="!finishedLoading"
              class="space"
              style="width: 100% !important"
              type="input"
            />
            <span
              v-if="finishedLoading"
              style="'border-color:rgba(255, 255, 255, 0.4)'; "
              :style="
                isDarkSkin
                  ? 'background: #17171A;opacity: 0.3;color:white'
                  : 'background: #efefef'
              "
              class="form-control"
              >{{ data.user_name }} |
              {{ data.created_at | myGlobalDay() }}</span
            >
          </b-input-group></b-col
        >
      </b-row>
    </validation-observer>
    <template #modal-footer>
      <div v-if="zeroPaymentTransactionId" />
      <div v-else>
        <b-button
          class="d-flex justify-content-center"
          variant="primary"
          @click="insertNotesWithoutPayment"
        >
          <b-spinner v-if="spinnerOn" small />
          SENT FOR REVIEW
        </b-button>
      </div>
    </template>
  </b-modal>
</template>

<script>
import modalMixin from "@/mixins/modal";
import { mapGetters, mapMutations } from "vuex";
import PendingPaymentsService from "../../../service/pending-payments.service";

export default {
  props: {
    zeroPaymentTransactionId: {
      type: String,
      default: null,
    },
    client: {
      type: Object,
    },
    typeTab: {
      type: Number,
    },
  },
  name: "AddZeroPayment",
  mixins: [modalMixin],
  data() {
    return {
      finishedLoading: false,
      date: "",
      min: new Date(1950, 0, 1, 0, 0, 0),
      max: new Date(2049, 11, 31, 24, 0, 0),
      minDate: new Date(),
      maxDate: new Date(2050, 9, 1),
      spinnerOn: false,
      content: "",
      ammount: 0,
      maskMoney: {
        decimal: ".",
        thousands: ",",
        prefix: "$",
        precision: 2,
        masked: false,
      },
      ownControl: false,
      data: [],
      validateEdit: false,
    };
  },
  async created() {
    this.ownControl = true;
    if (this.zeroPaymentTransactionId) {
      this.validateEdit = true;
      await this.getWithouPayment();
    }
    this.finishedLoading = true;
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
    }),
    roleId() {
      return this.currentUser.role_id;
    },
  },
  methods: {
    async getWithouPayment() {
      try {
        const response = await PendingPaymentsService.getWithoutPayment({
          id_transaction: this.zeroPaymentTransactionId,
        });

        this.data = response[0];
        this.content = this.data.comment;

        this.date = this.data.settlement_date;

        return this.data;
      } catch (error) {
        console.log(error);
        this.removePreloader();
      }
    },
    async insertNotesWithoutPayment() {
      try {
        const result = await this.$refs.form.validate();
        if (result) {
          this.spinnerOn = true;
          const params = {
            idca: this.client.client_account_id,
            iduser: this.currentUser.user_id,
            ammount: this.ammount,
            content: this.content,
            pzdate: this.date,
            account: this.client.client_account,
            client_name: this.client.client_name,
            requested_by_full_name: this.currentUser.fullName,
            payments_types_finantial: this.typeTab
          };
          const data = await PendingPaymentsService.makeZeroPayment(params);
          if (data.status === 200) {
            this.showSuccessSwal();
            this.$emit('onZeroPayment', this.client);
          }
        }
      } catch (e) {
        this.spinnerOn = false;
        this.showErrorSwal(e);
        return [];
      }
    },

    ...mapMutations({
      countPending: "SidebarStore/UPDATE_SIDEBAR_ITEM_PROPERTY",
    }),
  },
  mounted() {
    this.toggleModal("addZeroPaymentModal");
  },
};
</script>

<style></style>
