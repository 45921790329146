export default [
    {
        key: "client_name",
        label: "Client Name",
        visible: true,
    },
    {
        key: "payment_type",
        label: "Payment Type",
        visible: true,
    },
    {
        key: "amount",
        visible: true,
        thClass: "text-center",
        tdClass: "text-center",
    },
    {
        key: "balance",
        visible: true,
        thClass: "text-center",
        tdClass: "text-center",
    },
    {
        key: "client_state",
        label: "St",
    },
    {
        key: "recovered",
        label: "recovered",
        thClass: "text-center",
        tdClass: "text-center pt-1",
        visible: true,
    },
    {
        key: "start_date",
        label: "Enroll Date",
        thClass: "text-center",
        tdClass: "text-center pt-1",
        visible: true,
    },
    {
        key: "actions",
        label: "SMS",
        thClass: "text-center",
        tdClass: "text-center",
        visible: true,
    },
];
