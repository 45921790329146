<template>
  <div>
    <b-img
      v-b-tooltip.hover.top="`${typeOfPayment.title}`"
      :src="assetsImg + typeOfPayment.image"
      alt=""
      style="margin-right: 5px"
    />
    <span v-if="this.typePayment.type_payment == 'PA'"
      >({{ typePayment.day_payment }})</span
    >
  </div>
</template>

<script>
export default {
  props: {
    typePayment: {
      type: Object,
    },
  },
  data() {
    return {
      assetsImg: process.env.VUE_APP_BASE_URL_ASSETS,
    };
  },
  computed: {
    typeOfPayment() {
      let image = "";
      let title = "";
      if (this.typePayment.type_payment == "PA") {
        image = "/images/icons/paymenttype-auto.ico";
        title = "Automatic";
      }
      if (this.typePayment.type_payment == "MA") {
        image = "/images/icons/paymenttype-manual.ico";
        title = "Manual";
      }
      if (this.typePayment.type_payment == "OT") {
        image = "/images/icons/paymenttype-others.ico";
        title = "Others";
      }
      return { image, title };
    },
  },
};
</script>


